<template>
    <div class="god-ape-quest">
        <div class="god-ape-quest-container">
            <template v-if="!isLoading">
                <GodApeQuestButton v-if="!hasTeam" />
                <GodApeQuestInfo v-else />
            </template>
            <div class="god-ape-quest-loading" v-else>
                <img class="loader" src="@/assets/img/spinner-clear.svg" width="80" />
            </div>
            <GodApeQuestWallet v-if="hasTeam"/>

            <GodApeQuestTeamBar />
            <GodApeQuestMintedStats :good="questInfo && questInfo.godsMinted && questInfo.godsMinted.good" :evil="questInfo && questInfo.godsMinted && questInfo.godsMinted.evil"/>
            <GodApeQuestMap :map="questInfo.questMap" />
        </div>
    </div>
</template>
<script setup>
import GodApeQuestButton from "@/components/GodApeQuest/GodApeQuestButton.vue";
import GodApeQuestMap from "@/components/GodApeQuest/GodApeQuestMap.vue";
import { usePageWithWallet } from "@/composables/usePageWithWallet";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { computed, ref } from "vue-demi";
import GodApeQuestInfo from "@/components/GodApeQuest/GodApeQuestInfo.vue";
import GodApeQuestPhase from "@/components/GodApeQuest/GodApeQuestPhase.vue";
import GodApeQuestTeamBar from "@/components/GodApeQuest/GodApeQuestTeamBar.vue";
import { useApesStorageApi } from "@/api/useApesStorageApi";
import GodApeQuestMintedStats from "@/components/GodApeQuest/GodApeQuestMintedStats.vue";
import GodApeQuestWallet from "@/components/GodApeQuest/GodApeQuestWallet.vue";
const isLoading = computed(() => useGodApeQuestStore().isLoading)
const questInfo = ref({})
const fetchInfo = async () => {
    const res = await useApesStorageApi({ toast: { error: () => "Couldn't load phase progress" } })
        .exec({ url: "/quest/community-progress" })
    questInfo.value = res
}
fetchInfo()
const { isConnected } = usePageWithWallet({
    async onInit(connected) {
        if (connected) {
            await useGodApeQuestStore().init()
        }
    },
    onWalletChange: async (isConnected) => {
        if (isConnected) {
            await useGodApeQuestStore().init(true)
        }

    }
})
const godApeQuestStore = useGodApeQuestStore()
const hasTeam = computed(() => godApeQuestStore.hasTeam)
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&family=Oxanium:wght@500;700;800&display=swap");

.god-ape-quest {
    font-family: var(--font-montserrat);
    padding: 20px 40px;
    box-sizing: border-box;
    background-color: #000;
    flex: 1;
    &-container {
        max-width: 1400px;
        margin: 0 auto;
    }

    &-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 120px;
    }
    @media screen and (max-width: 576px) {
        padding: 0 15px;
        padding-top: 20px;
    }
}
</style>