<template>
    <div class="god-ape-quest-button-wrapper">
        <button class="god-ape-quest-button" @click.prevent="startQuest">Start your God Ape Quest</button>
    </div>
</template>
<script setup>import { useModal } from "@/composables/useModal";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useWeb3Store } from "@/store/web3";
import { connectWallet } from "@/web3";
import { ModalName } from "../Modal/modal-name";
const godQuestStore = useGodApeQuestStore()
const startQuest = async () => {
    if (!useWeb3Store().isConnected) {
        const success = await connectWallet()
        if(!success) return
    }
    await useGodApeQuestStore().init()

    if (!godQuestStore.hasTeam) {
        useModal().open({ name: ModalName.GodApeQuestStartModal })
    }
}
</script>
<style lang="scss">
.god-ape-quest-button {
    &-wrapper {
        display: flex;
        justify-content: center;
        padding: 20px 0;
    }
    width: 400px;
    height: 80px;
    border: 1px solid #fff;
    color: #fff;
    font-weight: bold;
    /*box-shadow: 0 0 3px 0 var(--ape-quest), 0 0 30px 1px rgba(247, 3, 186, 0.7); */
    box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem var(--ape-quest),
        0 0 0.8rem var(--ape-quest), 0 0 2.8rem var(--ape-quest),
        inset 0 0 1.3rem var(--ape-quest);
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 24px;
    text-align: center;
    text-shadow: 0 0 7px #fff, 0 0 21px #fff, 0 0 42px var(--ape-quest),
        0 0 82px var(--ape-quest), 0 0 92px var(--ape-quest);
    cursor: pointer;
    transition: 0.3s linear;
    &:hover {
        background-color: var(--ape-quest);
        box-shadow: none;
    }
    @media screen and (max-width: 576px) {
        font-size: 18px;
        height: 60px;
        width: 90%;
    }
}
</style>