<template>
    <div
        class="god-ape-quest-wallet-item"
        :class="{ satisfy: item.isSatisfy, error: !item.isSatisfy, selected: isSelected }"
    >
        <GodApeQuestWalletItemCard
            :item="itemInfo"
            @click.prevent="toggleIngredients"
            :hasIngredients="hasIngredients"
            :satisfy="item.isSatisfy"
        />
        <div class="god-ape-quest-wallet-item-state">
            <ICheckmark width="16" v-if="item.isSatisfy" />
            <ICross width="10" v-else />
        </div>
        <div
            class="god-ape-quest-wallet-item-count"
            v-if="count > 1"
            v-tooltip="'You should have ' + count + ' such traits'"
        >x{{ count }}</div>
    </div>
</template>
<script setup>
import GodApeQuestWalletItemCard from "./GodApeQuestWalletItemCard.vue";
import GodApeQuestWalletItem from "./GodApeQuestWalletItem.vue";
import { computed, ref } from "vue-demi";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import ICheckmark from "@/assets/icons/checkmark.svg?inline";
import ICross from "@/assets/icons/cross.svg?inline";
const props = defineProps({ item: Object, deepLevel: Number, isSelected: Boolean })
const emit = defineEmits(['update:showIngredients'])
const ingredientsToDisplay = computed(() => props.item.ingredients)
// console.log(props.item)
const hasIngredients = computed(() => ingredientsToDisplay.value?.length > 0)
const toggleIngredients = () => {
    if (!hasIngredients.value) return
    emit('update:showIngredients', { deepLevel: props.deepLevel, item: props.item, items: ingredientsToDisplay.value })
}
const hideIngredients = () => {
    emit('update:showIngredients', { deepLevel: props.deepLevel, item: props.item, items: null })
}
const count = computed(() => props.item?.item?.count)
const itemInfo = computed(() => {
    if ([FormulasIngredientType.METH, FormulasIngredientType.FORGE_BLOCK].includes(props.item.type)) {
        return { ...props.item.item, type: props.item.type }
    }
    return props.item.item
})
</script>
<style lang="scss">
.god-ape-quest-wallet-item {
    position: relative;
    border-radius: 5px;
    border: 2px solid var(--red);
    background-color: black;
    padding: 20px 15px;
    box-sizing: border-box;
    &-state {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        background: var(--red);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
    }
    &-count {
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(50%, -50%);
        color: #fff;
        border: 1px solid var(--red);
        background: var(--gray-light);
        border-radius: 50%;
        font-size: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 25px;
        padding: 0 3px;
        box-sizing: border-box;
        height: 25px;
    }
    &.error {
        border: 1px solid var(--red);
    }
    &.satisfy & {
        &-state {
            background: var(--green);
        }
        &-count {
            border-color: var(--green);
        }
    }
    &.satisfy {
        border: 2px solid var(--green);
    }
    &.selected {
        box-shadow: 0 0 5px 3px var(--purple);
    }
    &::before {
        content: "";
        position: absolute;
        bottom: -2px;
        left: 50%;
        transform: translate(-50%, 100%);
        height: 26px;
        width: 2px;
        background-color: var(--purple);
        display: none;
        opacity: 0.7;
    }
    &.selected::before {

    }
}
</style>