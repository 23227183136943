<template>
    <div
        class="god-ape-quest-wallet-item-card"
        :class="{ 'has-ingredients': hasIngredients, error: !hasIngredients }"
    >
        <ApeTraitCounts :item="item" />
        <img
            width="500"
            height="500"
            :src="image"
            alt="image"
            class="god-ape-quest-wallet-item-card__image"
        />
        <div class="god-ape-quest-wallet-item-card__name">{{ item.value }} {{ itemName }}</div>
        <div class="god-ape-quest-wallet-item-card__get">
            <div class="god-ape-quest-wallet-item-card__get-title">How to get it?</div>
            <a
                class="god-ape-quest-wallet-item-card__get-link"
                href="javascript:void(0)"
                @click.prevent="getAction"
            >{{ getName }}</a>
        </div>
    </div>
</template>
<script setup>import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { computed } from "vue-demi";
import ApeTrait from "@/components/ApeTrait/ApeTrait.vue";
import utilitiesMetadata from "@/config/utilities-metadata";
import { ApeTraitRarity } from "@/helpers/ape-constructor/ape-trait-rarity";
import ApeTraitCounts from "@/components/ApeTrait/ApeTraitCounts.vue";
import { Config } from "@/config";
import { useModal } from "@/composables/useModal";
import { ModalName } from "@/components/Modal/modal-name";
import { useRouter } from "vue-router";

const props = defineProps({ item: Object, hasIngredients: Boolean })
const itemType = computed(() => props.item.type)
const image = computed(() => {
    const traitImage = props.item.image || props.item.trait_out?.image
    if (itemType.value === FormulasIngredientType.OG_BULL) {
        return 'https://storage.mint.bullsandapesproject.com/bulls/images/OGBull.09342.png'
    }
    if (itemType.value === FormulasIngredientType.APE) {
        return require('@/assets/img/ape-constructor/categories/Background.jpg')
    }
    if (itemType.value === FormulasIngredientType.RARITY) {
        if (props.item.name === ApeTraitRarity.epic) {
            return 'https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/73f39776-60fa-4bd0-d990-75beb3909900/w=512'
        }
        if (props.item.name === ApeTraitRarity.og) {
            return 'https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/5ff1517b-16da-439a-e019-b97b81e8b700/w=512'
        }
    }
    if (itemType.value === FormulasIngredientType.FORMULA_TRAIT) {
        return 'https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/4de00504-09f4-469f-fdcb-b4fba116a700/w=512'
    }
    if (itemType.value === FormulasIngredientType.TRAIT_ANY) {
        return 'https://imagedelivery.net/r12dBNi95K-w7NNZkxF9WQ/caa9aa7f-f3cb-4aab-4457-da8230d3e700/w=512'
    }
    if (itemType.value === FormulasIngredientType.UTILITY) {
        return utilitiesMetadata[props.item.id]?.image
    }
    if (itemType.value === FormulasIngredientType.METH_MACHINE) {
        return utilitiesMetadata[20]?.image
    }
    if (itemType.value === FormulasIngredientType.METH) {
        return require('@/assets/img/meth-bag.jpg')
    }
    if (itemType.value === FormulasIngredientType.FORGE_BLOCK) {
        return require('@/assets/img/forge_block.jpg')
    }
    if (itemType.value === FormulasIngredientType.TEEN) {
        return 'https://storage.mint.bullsandapesproject.com/teens/images/TeenBull.00002.png'
    }
    return traitImage
})
const itemName = computed(() => {
    if (itemType.value === FormulasIngredientType.APE) return 'Any Ape'
    if (itemType.value === FormulasIngredientType.OG_BULL) return 'OG Bull (3 Breeds)'
    if (itemType.value === FormulasIngredientType.METH_MACHINE) return 'Meth Machine'
    if (itemType.value === FormulasIngredientType.METH) return 'Methane'
    if (itemType.value === FormulasIngredientType.FORGE_BLOCK) return 'Forge Block' + (props.item.value > 1 ? 's' : '')
    if (itemType.value === FormulasIngredientType.UTILITY) return utilitiesMetadata[props.item.id]?.name
    if (itemType.value === FormulasIngredientType.TRAIT_ANY) return 'Any Trait'
    if (itemType.value === FormulasIngredientType.FORMULA_TRAIT) return 'Any Formula Trait'

    const name = props.item.name
    return name?.[0]?.toUpperCase() + name?.slice(1)
})
const getName = computed(() => {
    if (props.hasIngredients) {
        return "See Ingredients"
    }
    if (itemType.value === FormulasIngredientType.TEEN) {
        return 'Buy on OpenSea'
    }
    if (itemType.value === FormulasIngredientType.APE) {
        return 'Buy on OpenSea'
    }
    if (itemType.value === FormulasIngredientType.OG_BULL) {
        return 'Buy on OpenSea'
    }
    if (props.hasIngredients) {
        return "Forge it"
    }
    if (itemType.value === FormulasIngredientType.METH) {
        return "Buy it"
    }
    if (itemType.value === FormulasIngredientType.FORGE_BLOCK) {
        return "Burn Traits"
    }
    if ([FormulasIngredientType.TRAIT, FormulasIngredientType.TRAIT_CATEGORY, FormulasIngredientType.RARITY, FormulasIngredientType.TRAIT_ANY].includes(itemType.value)) {
        if (props.item.name === ApeTraitRarity.og) {
            return 'De-equip from Ape'
        }
        if (props.item.source === 'shop') {
            return "Buy from Shop"
        }
        if (props.item.source === 'achievements') {
            return "Earn Achievements"
        }
        return "Open LootBox"
    }
    if (itemType.value === FormulasIngredientType.FORMULA_TRAIT && !props.hasIngredients) {
        return 'Forge it'
    }


    if (itemType.value === FormulasIngredientType.METH_MACHINE || itemType.value === FormulasIngredientType.UTILITY) {
        return 'Buy it on OpenSea'
    }


})
const router = useRouter()
const getAction = () => {
    if (props.hasIngredients) { return }
    if (itemType.value === FormulasIngredientType.TEEN) {
        window.open(Config.OSUrl.teens, '_blank')
    }
    if (itemType.value === FormulasIngredientType.APE) {
        window.open(Config.OSUrl.apes, '_blank')
    }
    if (itemType.value === FormulasIngredientType.OG_BULL) {
        window.open(Config.OSUrl.genesis, '_blank')
    }
    if (itemType.value === FormulasIngredientType.METH_MACHINE || itemType.value === FormulasIngredientType.UTILITY) {
        window.open(Config.OSUrl.utilities, '_blank')
    }
    if (itemType.value === FormulasIngredientType.METH) {
        useModal().open({ name: ModalName.BuyMethModal })
    }
    if (itemType.value === FormulasIngredientType.FORGE_BLOCK) {
        window.open(router.resolve({ name: "Formulas" }).fullPath, '_blank')
    }
    if ([FormulasIngredientType.TRAIT].includes(itemType.value)) {
        if (props.item.source === 'lootbox') {
            window.open(router.resolve({ name: "LootBox" }).fullPath, '_blank')
        }
        if (props.item.source === 'formula') {
            window.open(router.resolve({ name: "Formulas" }).fullPath, '_blank')
        }
        if (props.item.source === 'shop') {
            window.open(router.resolve({ name: "TraitShop" }).fullPath, '_blank')
        }
        if (props.item.source === 'achievements') {
            window.open(router.resolve({ name: "Achievements" }).fullPath, '_blank')
        }
        return
    }
    if([FormulasIngredientType.TRAIT_CATEGORY, FormulasIngredientType.RARITY, FormulasIngredientType.TRAIT_ANY].includes(itemType.value)) {
        window.open(router.resolve({ name: "LootBox" }).fullPath, '_blank')
    }
    if (itemType.value === FormulasIngredientType.FORMULA_TRAIT) {
        window.open(router.resolve({ name: "Formulas" }).fullPath, '_blank')

    }


}
</script>
<style lang="scss">
.god-ape-quest-wallet-item-card {
    min-width: 170px;
    min-height: 215px;
    box-sizing: border-box;
    border-radius: 5px;
    box-sizing: border-box;
    position: relative;
    &__name {
        text-align: center;
        font-weight: bold;
    }
    &__image {
        width: 100%;
        max-width: 100%;
        height: auto;
    }
    &__get {
        text-align: center;
        margin-top: 5px;
        &-title {
            text-transform: uppercase;
            opacity: 0.5;
            font-size: 12px;
            font-weight: bold;
        }
        &-link {
            color: var(--accent);
            font-weight: bold;
        }
    }
    &.has-ingredients {
        cursor: pointer;
    }
}
</style>