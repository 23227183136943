<template>
    <div class="god-ape-quest-team-bar-wrapper">
        <div class="god-ape-quest-team-bar-title">Team Statistics</div>
        <div class="god-ape-quest-team-bar">
            <div
                class="god-ape-quest-team-bar-active god-ape-quest-team-bar-vyl"
                :style="{ width: vylPercents + '%' }"
            >Vyl ({{ vylPercents }}%)</div>
            <div
                class="god-ape-quest-team-bar-active god-ape-quest-team-bar-ava"
            >Ava ({{ avaPercents }}%)</div>
        </div>
    </div>
</template>
<script setup>import { computed } from "vue-demi";


const vylCount = computed(() => 356)
const avaCount = computed(() => 270)
const vylPercents = computed(() => Math.floor(vylCount.value / (vylCount.value + avaCount.value) * 100))
const avaPercents = computed(() => 100 - vylPercents.value)
</script>
<style lang="scss">
.god-ape-quest-team-bar {
    display: flex;
    height: 50px;
    position: relative;
    &-wrapper {
        margin-top: 30px;
    }
    &-title {
        text-align: center;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 20px;
        text-transform: uppercase;
    }
    &-active {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
    }
    &-vyl {
        box-shadow: 0 5px 30px 0px var(--ape-quest-vyl);
        background-color: var(--ape-quest-vyl);
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
    }
    &-ava {
        background-color: var(--ape-quest-ava);
        box-shadow: 0 5px 30px 0px var(--ape-quest-ava);

        flex: 1;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }
    @media screen and (max-width: 576px) {
        &-title {
            font-size: 20px;
        }
    }
}
</style>