<template>
    <div class="god-ape-quest-wallet" v-click-outside="hideSubIngredients">
        <h2 class="god-ape-quest-wallet-title">God Ape Quest Ingredients</h2>
        <div class="god-ape-quest-wallet-subtitle">All you need to mint a God Ape</div>
        <div class="god-ape-quest-wallet-loading" v-if="isLoading">
            <img class="loader" src="@/assets/img/spinner-clear.svg" width="100" />
        </div>
        <div class="god-ape-quest-wallet-row" v-else>
            <GodApeQuestWalletItem
                :deepLevel="0"
                v-for="(item, idx) in ingredientsTree"
                :key="idx"
                :item="item"
                @update:showIngredients="updateIngredientsTree"
                :isSelected="isIngredientSelected(item)"
            />
        </div>
        <div class="god-ape-quest-wallet-ingredients-wrapper">
            <GodApeQuestWalletItemIngredients
                v-for="(ingredientRow, idx) in subIngredients"
                :items="ingredientRow.items"
                :item="ingredientRow.item"
                :key="idx"
                :isIngredientSelected="isIngredientSelected"
                :deepLevel="idx + 1"
                @update:showIngredients="updateIngredientsTree"
            />
        </div>
    </div>
</template>
<script setup>
import { formulasBuildFormulaIngredients } from "@/helpers/formulas/formulas-build-ingredients";
import { FormulasIngredientType } from "@/helpers/formulas/formulas-ingredient-types";
import { isSatisfyIngredient } from "@/helpers/formulas/formulas-satisfy-ingredient";
import { useFormulasStore } from "@/store/formulas/formulas";
import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { useMethBankStore } from "@/store/meth-bank";
import { useWalletCollectionStore } from "@/store/wallet-collection";
import { useWeb3Store } from "@/store/web3";
import { inited } from "@/web3";
import { markRaw } from "vue"
import { watchOnce } from "@vueuse/shared";
import { computed, ref, watch } from "vue-demi";
import GodApeQuestWalletItem from "./GodApeQuestWallet/GodApeQuestWalletItem.vue";
import GodApeQuestWalletItemIngredients from "./GodApeQuestWallet/GodApeQuestWalletItemIngredients.vue";

const formulasStore = useFormulasStore()
const web3Store = useWeb3Store()
const isLoading = ref(false)
const subIngredients = ref([])
const updateIngredientsTree = ({ deepLevel, items, item }) => {
    let newSubIngredients = []
    newSubIngredients = subIngredients.value.slice(0, deepLevel + 1)
    if (!items || !items.length || (subIngredients.value[deepLevel]?.items === items)) {
        newSubIngredients.pop()
    } else {
        const newArr = [...newSubIngredients]
        newArr[deepLevel] = markRaw({ items, item })
        newSubIngredients = newArr
    }
    subIngredients.value = newSubIngredients
    // console.log('here', subIngredients.value, subIngredients.value[deepLevel]?.items === items)
}
const isIngredientSelected = item => {
    return subIngredients.value.findIndex(itm => itm.item === item) > -1
}
const hideSubIngredients = () => {
    subIngredients.value = []
}
const fetchData = async () => {
    isLoading.value = true
    await Promise.all([
        useMethBankStore().fetchMethBank(),
        useWalletCollectionStore().fetchCollection(),
        useWalletCollectionStore().fetchApeTraits(),
        useFormulasStore().fetchFormulas(),
        useGodApeQuestStore().init()
    ])
    isLoading.value = false

}
const init = () => {
    fetchData()
    watch(() => web3Store.walletAddress, () => {
        fetchData()
    })
}
if (inited.value) {
    init()
} else {
    watchOnce(inited, () => {
        if (!web3Store.isConnected) return
        init()
    })
}

const formulas = computed(() => {
    const items = formulasStore.formulas.filter(formula => !!formula.team_type)
    const test = []
    items.forEach(item => {
        test.push({
            name: item.name,
            combinationId: item.combination_id
        })
    })
    return items
})
let formulaIds = {
    handTrait: 1,
    redPouch: 2,
    energyAbsorber: 3,
    restraintDevice: 4,
    skullsplitter: 5,
    avaTribeMask: 6,
    elementIncubator: 7,
    godElement: 8,
    vylsPortalDevice: 9,
    evilApeCompass: 10,
    goodApeCompass: 11,
    portal: 12,
}
formulaIds = Object.keys(formulaIds).reduce((obj, key) => {
    obj[key] = `GC${formulaIds[key]}`
    return obj
}, {})
const getFormulaById = (id) => {
    const item = formulas.value.find(item => item.combination_id === id)
    let ingredients = formulasBuildFormulaIngredients(item)
    ingredients = ingredients.map(ingredient => {
        const isSelectable = formulasStore.isSelectable(ingredient.type)
        let isSatisfy = false
        if (isSelectable) {
            // check if can select
            if (ingredient.type === FormulasIngredientType.TAGS) {
                isSatisfy = useWalletCollectionStore().apeTraits.filter(trait => trait.tags?.includes(ingredient.item.name)).length > 0
            }

            if (ingredient.type === FormulasIngredientType.TRAIT_CATEGORY) {
                isSatisfy = useWalletCollectionStore().apeTraits.filter(trait => {
                    return trait.categoryId == ingredient.item.id
                }).length > 0
            }
            if (ingredient.type === FormulasIngredientType.RARITY) {
                isSatisfy = useWalletCollectionStore().apeTraits.filter(trait => trait.rarity.toLowerCase() == ingredient.item.name.toLowerCase()).length > 0
            }

            if (ingredient.type === FormulasIngredientType.FORMULA_TRAIT) {
                isSatisfy = useWalletCollectionStore().apeTraits.filter(trait => {
                    if (trait.team_type && trait.team_type !== 'all') return false
                    if (ingredient.item.name === 'all') {
                        return !!trait.is_formula_trait
                    } else {
                        return trait.team_type === ingredient.item.name
                    }
                }).length > 0
            }
            if (ingredient.type === FormulasIngredientType.TRAIT_ANY) {
                isSatisfy = useWalletCollectionStore().apeTraits.length > 0
            }

            if (ingredient.type === FormulasIngredientType.OG_BULL) {
                isSatisfy = useWalletCollectionStore().genesis.length >= 2
            }
            if (ingredient.type === FormulasIngredientType.METH_MACHINE) {
                isSatisfy = useWalletCollectionStore().methMachines.length > 0

            }
            if (ingredient.type === FormulasIngredientType.APE) {
                isSatisfy = useWalletCollectionStore().apes.length >= 4
            }
        } else {
            isSatisfy = isSatisfyIngredient(ingredient, ingredient.idx)
        }
        return { ...ingredient, isSatisfy }
    })
    const traitInWallet = () => {
        if (item.trait_out.type === 'utility') {
            console.log('utility', item)
            const utility = useWalletCollectionStore().utilities.find(utility => utility.id == item.trait_out.id)
            console.log('omg', utility)
            return { offChain: utility?.offChainCount || 0, balance: utility?.onChainCount || 0 }
        }
        return useWalletCollectionStore().apeTraits.find(trait => trait.id == item.trait_out.id) || { offChain: 0, balance: 0 }
    }
    const inWalletData = traitInWallet()
    return {
        item: {
            ...item,
            ...inWalletData,
            canForge: ingredients.every(item => item.isSatisfy),
        }, ingredients,
        isSatisfy: inWalletData.offChain > 0 || inWalletData.balance > 0
    }
}

const ingredientsTree = computed(() => {
    if (!formulas.value.length) return

    const res = []
    const buildGodElement = () => {
        const { item: godElement, ingredients } = getFormulaById(formulaIds.godElement)

        const godElements = [1179, 1180, 1181, 1182, 1183, 1184, 1185, 1186, 1187, 1188, 1189, 1190, 1191]
        const inWalletBalance = useWalletCollectionStore().apeTraits.filter(trait => godElements.includes(trait.id)).reduce((sum, trait) => ({ balance: (trait.balance || 0) + sum.balance, offChain: (trait.offChain || 0) + sum.offChain }), { balance: 0, offChain: 0 })
        const isSatisfy = inWalletBalance.balance > 0 || inWalletBalance.offChain > 0
        const godElementTree = {
            item: { ...godElement, ...inWalletBalance },
            ingredients: ingredients.map(item => {
                if (item.type === FormulasIngredientType.UTILITY) {
                    // incubator
                    const { item: incubator, ingredients, isSatisfy } = getFormulaById(formulaIds.elementIncubator)
                    
                    return {
                        item: {
                            ...item.item,
                            ...incubator,
                        },
                        type: item.type,
                        ingredients,
                        isSatisfy
                    }
                }
                return item
            }),
            isSatisfy

        }
        return godElementTree
    }
    const buildCompass = () => {
        const team = useGodApeQuestStore().team
        let { item: compass, isSatisfy, ingredients } = getFormulaById(team === 'ava' ? formulaIds.goodApeCompass : formulaIds.evilApeCompass)
        const apeIngredient = ingredients.find(ing => ing.type === FormulasIngredientType.APE)
        apeIngredient.item.count = 4
        ingredients = ingredients.filter(ing => ing.type !== FormulasIngredientType.APE)
        ingredients.push(apeIngredient)
        const traitIdx = ingredients.findIndex(ing => ing.type === FormulasIngredientType.TRAIT)
        const buildSubTraitIngredients = (item, id) => {
            const { item: traitIngredient, ingredients } = getFormulaById(id)
            return {
                item: {
                    ...item.item,
                    ...traitIngredient
                },
                type: item.type,
                ingredients, isSatisfy
            }
        }
        const buildTraitIngredients = (item) => {
            const { item: traitIngredient, isSatisfy, ingredients } = getFormulaById(team === 'ava' ? formulaIds.avaTribeMask : formulaIds.skullsplitter)
            const avaPunchIdx = ingredients.findIndex(ing => ing.item.id === 1061)
            const avaSaluteIdx = ingredients.findIndex(ing => ing.item.id === 1060)
            const vylEnergyAbsorberIdx = ingredients.findIndex(ing => ing.item.id === 1059)
            const vylRestraintDevice = ingredients.findIndex(ing => ing.item.id === 1062)
            if (avaPunchIdx > -1 && avaSaluteIdx > -1) {
                ingredients[avaPunchIdx] = buildSubTraitIngredients(ingredients[avaPunchIdx], formulaIds.redPouch)
                ingredients[avaSaluteIdx] = buildSubTraitIngredients(ingredients[avaSaluteIdx], formulaIds.handTrait)
            }
            if (vylEnergyAbsorberIdx > -1 && vylRestraintDevice > -1) {
                ingredients[vylEnergyAbsorberIdx] = buildSubTraitIngredients(ingredients[vylEnergyAbsorberIdx], formulaIds.energyAbsorber)
                ingredients[vylRestraintDevice] = buildSubTraitIngredients(ingredients[vylRestraintDevice], formulaIds.restraintDevice)
            }
            return {
                item: {
                    ...item.item,
                    ...traitIngredient
                },
                type: item.type,
                ingredients,
                isSatisfy
            }
        }
        ingredients[traitIdx] = buildTraitIngredients(ingredients[traitIdx])
        return {
            item: compass,
            ingredients: ingredients, isSatisfy
        }
    }
    const buildVylsPortalDevice = () => {
        const { item: traitIngredient, ingredients, isSatisfy } = getFormulaById(formulaIds.vylsPortalDevice)

        return { item: traitIngredient, ingredients, isSatisfy }
    }
    const buildTeen = () => {
        const isSatisfy = useWalletCollectionStore().teens.length > 0
        return {

            item: {
                name: "Teen",
                type: 'teen'
            },
            ingredients: [],
            isSatisfy
        }
    }
    const buildPortal = () => {
        const { item: traitIngredient, ingredients, isSatisfy } = getFormulaById(formulaIds.portal)
        return { item: traitIngredient, ingredients, isSatisfy }
    }
    res.push(buildGodElement())
    res.push(buildCompass())
    res.push(buildVylsPortalDevice())
    res.push(buildPortal())
    res.push(buildTeen())
    return res
})

</script>
<style lang="scss">
.god-ape-quest-wallet {
    position: relative;

    &-title {
        text-align: center;
        margin-bottom: 5px;
        padding-bottom: 0;
    }

    &-subtitle {
        text-align: center;
        margin-bottom: 30px;
        opacity: 0.7;
    }
    &-loading {
        text-align: center;
    }
    &-row {
        display: flex;
        align-items: center;
        padding: 10px;
        margin: -10px;
        margin-bottom: 10px;
        > div {
            width: calc(100% / 5);
            margin: 5px;
            min-width: 220px;
        }
        overflow: auto;
    }
    &-item {
    }

    &-ingredients-wrapper {
       
    }
    @media screen and (max-width: 1200px) {
        &-row {
        }
    }
}
</style>