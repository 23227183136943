<template>
    <div class="god-ape-quest-info" :class="team">
        <div class="god-ape-quest-title" :class="team">
            <template v-if="team == 'vyl'">
                <span class="god-ape-quest-title-moto">Let’s conquer everything in sight</span>
                <span class="god-ape-quest-title-team">You’re a part of Vyl’s army now</span>
            </template>
            <template v-if="team == 'ava'">
                <span class="god-ape-quest-title-moto">Let’s stand against tyranny</span>

                <span class="god-ape-quest-title-team">You’re a part of Ava’s pride now</span>
            </template>
        </div>
        <div class="god-ape-quest-info-completed" v-if="isPhaseCompleted">
            <div class="god-ape-quest-info-completed-title">CONGRATULATIONS!</div>
            <div
                class="god-ape-quest-info-completed-text"
            >
You Have Successfully Completed All Tasks of the Current Phase
</div>
        </div>
        <div class="god-ape-quest-info-button-wrapper">
            <RouterLink
                class="god-ape-quest-info-button"
                :to="{ name: 'Formulas', hash: '#god_ape_quest' }"
            >
            Mint your God Ape here
</RouterLink>
        </div>
        <div class="god-ape-quest-info-row">
            <div class="god-ape-quest-info-video">
                <a target="_blank" href="https://youtu.be/-ALURL_Ni5Y">
                    <IPlay width="16" />Rewatch video
                </a>
            </div>
            <div class="god-ape-quest-info-guide">
                <a
                    target="_blank"
                    href="https://www.bullsandapesproject.com/blog/god-ape-quest-guide"
                >
                    <IZoom width="14" />Quest Guide
                </a>
            </div>
        </div>
    </div>
</template>
<script setup>import { useGodApeQuestStore } from "@/store/god-ape-quest/god-ape-quest";
import { computed } from "vue-demi";
import IPlay from "@/assets/icons/play.svg?inline"
import IZoom from "@/assets/icons/search.svg?inline"
import ICheckmark from "@/assets/icons/checkmark.svg?inline"
const team = computed(() => useGodApeQuestStore().team)
const godApeQuestStore = useGodApeQuestStore()

const isPhaseCompleted = computed(() => godApeQuestStore.currentPhase > godApeQuestStore.communityPhase)
</script>
<style lang="scss">
.god-ape-quest-title {
    font-weight: bold;
    font-size: 24px;
    color: var(--ape-quest);
    text-align: center;
    padding-top: 10px;
    text-transform: uppercase;
    &-moto {
    }
    &-team {
        display: block;
    }
    &.vyl & {
        &-team {
            color: var(--ape-quest-vyl);
        }
    }
    &.ava & {
        &-team {
            color: var(--ape-quest-ava);
        }
    }
}
.god-ape-quest-info {
    background-position: center right;
    background-size: cover;
    margin: 0 -40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    box-sizing: border-box;
    &.vyl {
        background-image: url(~@/assets/img/god-ape-quest/vyl-banner.jpg);
        min-height: 400px;
    }
    &.ava {
        background-image: url(~@/assets/img/god-ape-quest/ava-banner.jpg);
        min-height: 400px;
    }
    > div {
        position: relative;
        z-index: 5;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: rgba(0, 0, 0, 0.7);
    }
    &-button {
        text-decoration: none;
        background-color: #3dd1d1;
        border-radius: 5px;
        padding: 10px 30px;
        color: #fff;
        font-weight: bold;
        font-family: inherit;
        transition: 0.2s;
        font-size: 18px;
        &:hover {
            filter: brightness(0.8);
        }
        &-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 15px;
        }
    }
    &-row {
        display: flex;
        align-items: center;
        margin-top: 15px;

    }
    &-video {
        display: flex;
        justify-content: center;
        a {
            display: flex;
            align-items: center;
            color: var(--accent);
            transition: 0.2s;
            svg {
                margin-right: 4px;
            }
            &:hover {
                color: #fff;
            }
        }
    }
    &-guide {
        margin-left: 14px;

        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            border-bottom: 1px solid currentColor;
            color: var(--accent);
            transition: 0.2s;
            svg {
                margin-right: 4px;
            }
            &:hover {
                color: #fff;
            }
        }
    }
    &-completed {
        text-align: center;
        padding: 10px 0;
        font-weight: bold;

        &-title {
            font-size: 24px;
            color: var(--green);
        }
        &-text {
            font-size: 18px;
        }
    }
    @media screen and (max-width: 576px) {
        margin: 0 -15px;
        .god-ape-quest-title {
            font-size: 18px;
        }
        &-completed {
            &-title {
                font-size: 18px;
            }
            &-text {
                font-size: 14px;
            }
        }
    }
}
</style>