<template>
    <div class="god-ape-quest-map">
        <div class="god-ape-quest-map-zoomer">
            <VueImageZoomer
                img-class="god-ape-quest-map-image"
                :click-zoom="true"
                :zoom-amount="3"
                :regular="image"
                :zoom="imageZoom"
                :show-message="false"
            />
        </div>
    </div>
</template>
<script setup>
import { resizeImageCF } from '@/helpers/resize-image-cloudflare';
import { computed } from 'vue-demi';
import { VueImageZoomer } from 'vue-image-zoomer'
import 'vue-image-zoomer/dist/style.css';
const props = defineProps({ map: String })

const image = computed(() => resizeImageCF(props.map || '', 1920))
const imageZoom = computed(() => resizeImageCF(props.map || '', 8000))
// const image = require('@/assets/img/god-ape-quest/god-ape-quest-map.png')
// const imageZoom = require('@/assets/img/god-ape-quest/god-ape-quest-map-zoom.png')
</script>
<style lang="scss">
.god-ape-quest-map {
    margin-top: 60px;
    max-width: 100%;
    border-radius: 10px;
    position: relative;
    &-image {
        max-width: 100%;
        height: auto;
    }
    &-zoomer {
        position: relative;
        z-index: 2;
    }
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        box-shadow: 0 0 40px 15px rgba(255, 255, 255, 0.8);
        top: 30px;
        left: 30px;
        right: 30px;
        bottom: 30px;
    }
}
</style>